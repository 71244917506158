import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

import { $session } from "framework";
import { reactive } from "vue";
import { useSiteStore } from "@/store/site";
import router from "@/router";

interface UserInfo {
  NAME: string;
  USER_ID: string;
  USER_NO: string;
  USER_ROLES: {
    dotSaasVision: string;
    dotSaasClass: string;
    dotSaasSupport: string;
  };
}

interface State {
  isLogin: boolean;
  userInfo: UserInfo;
}

interface Store {
  param: {
    a: string;
    r: string;
    u: string;
  } | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginBtn',
  setup(__props) {

const siteStore: Store = useSiteStore();

const state: State = reactive<State>({
  isLogin: $session.isLogin(),
  userInfo: {
    NAME: "",
    USER_ID: "",
    USER_NO: "",
    USER_ROLES: {
      dotSaasVision: "",
      dotSaasClass: "",
      dotSaasSupport: "",
    },
  },
});

if (state.isLogin) {
  const userName = $session.getUserInfo()?.NAME?.slice(0, 1) || "U";
  const userId = $session.getUserInfo().USER_ID;
  const userNo = $session.getUserNo();
  const userRole = $session.getUserRoles("dot-saas-support");
  state.userInfo.NAME = userName;
  state.userInfo.USER_NO = userNo;
  state.userInfo.USER_ID = userId;
  state.userInfo.USER_ROLES.dotSaasSupport = userRole;
}

const login = () => {
  router.push("/login");
};

const profile = () => {
  const sso = $session.getSsoDomain();
  const s = $session.getSiteNo();
  const c = $session.getCompNo();
  const a = siteStore.param?.a;
  const cb = window.location.origin;

  const url = `${sso}/basic?s=${s}&c=${c}&a=${a}&cb=${cb}/login-callback`;

  window.open(url);
};

const logOut = () => {
  state.isLogin = false;
  $session.signOut();
  window.location.reload();
};

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (!state.isLogin)
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        variant: "text",
        ripple: false,
        style: {"font-size":"16px","text-transform":"none","color":"#212529"},
        onClick: login
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("로그인")), 1)
        ]),
        _: 1
      }))
    : (state.isLogin)
      ? (_openBlock(), _createBlock(_component_v_menu, { key: 1 }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps(props, {
              style: {"width":"56px","height":"56px","border-radius":"50%","font-size":"32px","font-weight":"bold","color":"#fff","background":"#aaabab","text-transform":"none"},
              ripple: false
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(state.userInfo.NAME), 1)
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, { style: {"background":"#aaabab","width":"160px","margin-top":"10px","box-shadow":"none"} }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, { class: "list" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "text",
                          class: "listBtn",
                          onClick: profile,
                          ripple: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("프로필")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item, { class: "list" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "text",
                          class: "listBtn",
                          onClick: logOut,
                          ripple: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("로그아웃")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { "aria-label": "Main Menu" }
const _hoisted_2 = {
  class: "menu-list",
  role: "menubar"
}
const _hoisted_3 = ["aria-current", "tabindex", "onKeydown", "onClick"]

import { onMounted, reactive, watch } from "vue";
import { $menu, $session } from "framework";
import global from "global";

interface MenuItem {
  MENU_ID: string;
  MENU_NAME: string;
  PARENT_MENU_ID: string;
  MENU_URL: string;
  SORT: number;
  children: MenuItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuBtn',
  setup(__props) {

const menuStore: { MENU_LIST: Promise<MenuItem[]> } = $menu();

const state = reactive({
  menuList: [] as MenuItem[],
  currentFocusIndex: 0,
});

const getIndexFirstChars = (startIndex: number, char: string) => {
  for (let i = startIndex; i < state.menuList.length; i++) {
    const menuName = state.menuList[i].MENU_NAME;

    if (menuName.charAt(0).toLowerCase() === char) {
      return i;
    }
  }
  return -1;
};

const isPrintableCharacter = (str: string) => {
  return str.length === 1 && str.match(/\S/);
};

const setFocusByFirstCharacter = (char: string, index: number) => {
  let start = index + 1;

  char = char.toLowerCase();

  if (start >= state.menuList.length) {
    start = 0;
  }

  index = getIndexFirstChars(start, char);

  // If not found in remaining slots, check from beginning
  if (index === -1) {
    index = getIndexFirstChars(0, char);
  }

  return index;
};

// 키보드 이벤트 처리
const handleKeyDown = (event: KeyboardEvent, index: number) => {
  const menuItems = document.querySelectorAll('[role="menuitem"]');
  let key = event.key;
  let newIndex = index;
  let flag = false;

  switch (key) {
    case "ArrowRight":
    case "ArrowDown":
      flag = true;
      newIndex = (index + 1) % state.menuList.length;
      break;

    case "ArrowLeft":
    case "ArrowUp":
      flag = true;
      newIndex = (index - 1 + state.menuList.length) % state.menuList.length;
      break;

    case "Home":
      flag = true;
      newIndex = 0;
      break;

    case "End":
      flag = true;
      newIndex = state.menuList.length - 1;
      break;

    case "Enter":
    case " ":
      flag = true;
      navigateToMenu(state.menuList[index].MENU_URL);
      return;
    default:
      if (isPrintableCharacter(key)) {
        newIndex = setFocusByFirstCharacter(key, index);
        flag = true;
      }
      break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }

  // 포커스 업데이트
  const targetMenuItem = menuItems[newIndex] as HTMLElement;
  if (targetMenuItem) {
    targetMenuItem.focus();
    state.currentFocusIndex = newIndex;
    updateTabIndexes(newIndex);
  }
};

// tabindex 업데이트
const updateTabIndexes = (activeIndex: number) => {
  const menuItems = document.querySelectorAll('[role="menuitem"]');
  menuItems.forEach((item, index) => {
    item.setAttribute("tabindex", index === activeIndex ? "0" : "-1");
  });
};

watch(
  () => menuStore.MENU_LIST,
  async (newValue) => {
    state.menuList = await newValue;
  }
);

onMounted(() => {
  bindMenuList();
});

const bindMenuList = () => {
  if (global.isLogin()) {
    const menu = $menu();
    const userRole = $session.getUserRoles("dot-saas-support");
    menu.bindMenuList(userRole);
  } else {
    global.setSession();
  }
};

const isActive = (MENU_URL: string) => {
  return process.env[MENU_URL] === window.location.origin;
};

const navigateToMenu = (MENU_URL: string) => {
  const targetUrl = process.env[MENU_URL];

  if (targetUrl) {
    window.location.href = targetUrl;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.menuList, (menu, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: menu.MENU_ID,
          class: "menu-item",
          role: "none"
        }, [
          _createElementVNode("a", {
            "aria-current": isActive(menu.MENU_URL) ? 'page' : undefined,
            tabindex: index === 0 ? 0 : -1,
            href: "#",
            role: "menuitem",
            onKeydown: ($event: any) => (handleKeyDown($event, index)),
            onClick: _withModifiers(($event: any) => (navigateToMenu(menu.MENU_URL)), ["prevent"])
          }, _toDisplayString(menu.MENU_NAME), 41, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "menu-list" }
const _hoisted_2 = ["aria-disabled", "href"]

import { $session } from "framework";
import { computed, reactive } from "vue";
import OpenInNew from "@/components/atoms/icon/OpenInNew.vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenu',
  setup(__props) {

const { t } = useI18n();

const userRole = $session.getUserRoles()["dot-saas-support"];
const isAdmin = userRole?.includes("ADMIN") || false;

const state = reactive({
  isAdmin: isAdmin,
});

const menuList = computed(() => (state.isAdmin ? adminMenu : userMenu));

const userMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];

const adminMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, (menu, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: "menu-item"
      }, [
        (menu.type === 'router')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              "aria-disabled": menu.disabled ? 'true' : undefined,
              to: menu.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)(menu.title)), 1)
              ]),
              _: 2
            }, 1032, ["aria-disabled", "to"]))
          : (menu.type === 'blank')
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                "aria-disabled": menu.disabled ? 'true' : undefined,
                href: menu.link,
                class: "d-flex align-center",
                rel: "noopener noreferrer",
                target: "_blank"
              }, [
                _createTextVNode(_toDisplayString(_unref(t)(menu.title)) + " ", 1),
                _createVNode(OpenInNew)
              ], 8, _hoisted_2))
            : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseLayout from "@/layouts/template/BaseLayout.vue";
import DotHeader from "@/layouts/inc/Header.vue";
import DotSideMenu from "@/layouts/inc/SideMenu.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenuLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(BaseLayout, null, {
    header: _withCtx(() => [
      _createVNode(DotHeader)
    ]),
    sideMenu: _withCtx(() => [
      _createVNode(DotSideMenu)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "headerSlot"
}
const _hoisted_2 = {
  key: 2,
  ref: "footerSlot"
}

import "@/assets/css/layout.css";

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header")
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.$slots.sideMenu)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
            key: 1,
            class: "side-menu",
            width: "300"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "sideMenu")
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, { scrollable: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { class: "container" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      }),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer")
          ], 512))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/logo.png'


import BtnMenu from "@/components/header/MenuBtn.vue";
import BtnLangChange from "@/components/header/LangChangeBtn.vue";
import BtnLogin from "@/components/header/LoginBtn.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    elevation: 0,
    class: "header"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "logo" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: "/",
            class: "link"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                alt: "Dot Inc.",
                src: _imports_0
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(BtnMenu),
      _createVNode(_component_v_spacer),
      _createVNode(BtnLogin),
      _createVNode(BtnLangChange)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import { ref, watch } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const layout = ref();

watch(
  () => route.meta.layout,
  (newLayout) => {
    layout.value = newLayout;
  }
);

return (_ctx: any,_cache: any) => {
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value)))
    ]),
    _: 1
  }))
}
}

})